/* height of the container */
.leaflet-container {
    height: 92vh;
    width: 100vw;
    border-radius: 0 0 2rem 2rem;
    z-index: 0;
    position: absolute;
}

.cluster-icon {
    background-color: #333;
    height: 2em;
    width: 2em;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.2rem;
    box-shadow: 0 0 0px 5px #fff;
}

.localisation {
    background: #fff;
    padding: 1.5rem;
    border-radius: 1rem;
    position: absolute;
    bottom: 22%;
    transform: translateY(50%) translateX(-50%);
    left: 50%;
    z-index: 1;
    width: 95vw;
    filter: drop-shadow(0px 0px 4px #333);
}

.localisation .icons {
    width: 30px;
    color: var(--main-color);
}

#adrs {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
