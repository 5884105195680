@-webkit-keyframes pulse {
  4% {
    transform: translate(-50%, -50%) scaleX(1.1);
  }

  8% {
    transform: translate(-50%, -50%);
  }

  24%,
  56%,
  88% {
    transform: translate(-50%, -50%) scale(0.8);
  }

  40%,
  72% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

@keyframes pulse {
  4% {
    transform: translate(-50%, -50%) scaleX(1.1);
  }

  8% {
    transform: translate(-50%, -50%);
  }

  24%,
  56%,
  88% {
    transform: translate(-50%, -50%) scale(0.8);
  }

  40%,
  72% {
    transform: translate(-50%, -50%) scale(1.2);
  }
}

@-webkit-keyframes dot {
  8% {
    transform: scale(0);
  }

  16% {
    transform: scale(1) rotate(30deg) translateX(48px);
  }

  95% {
    transform: scale(1) rotate(1000deg) translateX(48px);
  }

  100% {
    transform: scale(0.125) rotate(1050deg) translate(52px, -6px);
  }
}

@keyframes dot {
  8% {
    transform: scale(0);
  }

  16% {
    transform: scale(1) rotate(30deg) translateX(48px);
  }

  95% {
    transform: scale(1) rotate(1000deg) translateX(48px);
  }

  100% {
    transform: scale(0.125) rotate(1050deg) translate(52px, -6px);
  }
}

:root {
  --main-color: #04bf94;
  --red-color: #d3252b;
}

a {
  text-decoration: none;
  color: inherit;
}

.noAccount {
  margin-bottom: 30px;
  transition: all 500ms;
}

.noAccount:hover {
  color: var(--main-color);
}

.main-color {
  color: var(--main-color);
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  scrollbar-width: thin;
  scrollbar-color: var(--main-color) #fff;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--main-color);
  border-radius: 20px;
}

html,
body {
  margin: 0;
  padding: 0;
  /* overflow: hidden; */
}

/* body {
    background-color: #f1f0f5;
} */
.title.titleMain {
  background: var(--main-color);
  color: #fff;
  padding: 2rem;
  text-align: center;
  text-transform: capitalize;
}

.get-categories-page {
  width: 90%;
  margin: 2rem auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: baseline;
}

.get-categories-page a {
  margin-bottom: 15px;
  cursor: pointer;
}

.get-categories-page p {
  padding: 2rem;
  background: #fff;
  border-radius: 1rem;
  filter: drop-shadow(0 0 2px #333);
  text-transform: capitalize;
}

.get-actus-page {
  width: 90%;
  margin: 15px auto;
}

.faq-body {
  background-color: #f1f0f5;
}

.faq-row {
  margin-bottom: 10px;
  border-radius: 15px;
  padding: 10px !important;
  background-color: white;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY
  .styles_icon-wrapper__2cftw {
  max-width: 260px !important;
}

.actuContainer {
  width: 90%;
  margin: 2rem auto;
}

.actuContainer .actuTitle {
  text-align: center;
  background: #fff;
  margin: 1rem;
  padding: 1rem;
  border-radius: 1rem;
  display: block;
  font-size: 1.3em;
  font-weight: bolder;
  position: relative;
}

.actuContent {
  clear: both;
  width: 100%;
  height: 0px;
  overflow: hidden;
  text-align: center;
  transition: height 0.4s ease;
}

.actuContent li {
  padding: 30px;
}

.actus input[data-id="actu"] {
  position: absolute;
  opacity: 0;
  height: 0px;
}

.actus input[data-id="actu"]:checked ~ .actuContent {
  height: auto;
}

.actus input[data-id="actu"]:checked + .actuTitle {
  background-color: var(--main-color);
  color: #fff;
}

.actus input[data-id="actu"]:checked + .actuTitle::before {
  transform: rotate(0deg);
}

.actus input[data-id="actu"] + .actuTitle::before {
  content: "\25BC";
  position: absolute;
  right: 2rem;
  transform: rotate(180deg);
}

.actuContainer .actuContent {
  background: #fff;
  text-align: justify;
  text-indent: 3rem;
  border-radius: 1rem;
}

#navBar {
  position: absolute;
  bottom: 0;
  width: 100%;
  filter: drop-shadow(0px -1px 3px #333);
}

#navBar .items {
  padding: 0 1rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* margin: 0 1rem; */
  background: #fff;
  /* border-radius: 0.8rem 0.8rem 0 0; */
}

#navBar .items .item {
  width: 60px;
  height: 60px;
  position: relative;
  z-index: 2;
}

#navBar .items .item .link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

#navBar .items .item .link .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  color: #000;
}

#navBar .items .item.active .link .icon {
  background-color: var(--main-color);
  color: white;
  fill: white;
  transform: translateY(-27px);
  border-radius: 100%;
  transition: transform 250ms;
  transition-delay: 250ms;
}

#navBar .items .item .link .icon .icons {
  width: 30px;
  height: 30px;
}

#navBar .items .item.active .link .icon:after {
  content: "";
  height: 100%;
  width: 100%;
  background-color: var(--main-color);
  border-radius: 100%;
  filter: blur(5px);
  opacity: 0.5;
  position: absolute;
  top: 10px;
  left: 0;
  z-index: -1;
}

#navBar .items #indicator {
  position: absolute;
  width: 70px;
  height: 70px;
  background-color: #fff;
  top: -35px;
  border-radius: 100%;
  z-index: 1;
  transition: 500ms;
}

#navBar .items #indicator::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -28px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: transparent;
  box-shadow: 15px 18px #fff;
}

#navBar .items #indicator::after {
  content: "";
  position: absolute;
  top: 5px;
  right: -28px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: transparent;
  box-shadow: -15px 18px #fff;
}

#navBar .items #indicator #indicatorTop {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  transform: translateX(-50%) translateY(-28px);
  left: 50%;
}

#navBar .items #indicator #indicatorTop path {
  fill: #fff;
  stroke: none;
}

#navBar .items .item .link .markerNews {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: var(--red-color);
  top: 50%;
  left: 70%;
  transform: translate(-50%, -100%);
  z-index: 1;
  filter: drop-shadow(0 0px 4px var(--red-color));
  pointer-events: none;
  opacity: 0;
  transition: opacity 250ms;
}

#container {
  background-color: #f1f0f5;
  overflow: hidden;
  height: 100vh;
  /* margin: 1rem; */
  /* padding: 2rem; */
  /* border-radius: 1rem; */
}

#container2 {
  /* overflow: hidden; */
  overflow: auto;
  height: 93vh;
}

#container2 .setting {
  border-bottom: 1px solid rgba(4, 191, 148, 0.3);
  text-decoration: none;
  display: block;
  color: #000;
}

#container2 .setting h2 {
  padding: 1rem 2rem;
}

#container2 .setting h2 svg {
  stroke: var(--main-color);
  fill: var(--main-color);
  margin-right: 10px;
}

.masjidLayout .countDown {
  display: flex;
  gap: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.masjidLayout .countDown .circle {
  position: relative;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.masjidLayout .countDown .circle svg {
  position: relative;
  width: 150px;
  height: 150px;
  transform: rotate(270deg);
}

.masjidLayout .countDown .circle svg circle {
  width: 100%;
  height: 100%;
  fill: transparent;
  stroke-width: 8;
  stroke: #ffffff3d;
  transform: translate(5px, 5px);
}

.masjidLayout .countDown .circle svg circle:nth-child(2) {
  stroke: #fff;
  stroke-dasharray: 440;
  stroke-dashoffset: 440;
}

.masjidLayout .countDown div {
  position: absolute;
  text-align: center;
  font-weight: bold;
  color: #fff;
  font-size: 1.5rem;
}

.masjidLayout .countDown .countDownTime .countDownText {
  position: absolute;
  transform: translateX(-50%), translateY(-10px);
  font-size: 0.8rem;
  font-weight: normal;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  width: 100%;
  display: block;
}

#masjidLayoutF {
  /* FAJR */
  background: linear-gradient(180deg, rgba(0, 174, 239, 1) 0%, rgba(102, 192, 227, 1) 50%, rgba(165, 206, 211, 1) 100%);
}

#masjidLayoutD {
  /* DHUHR */
  background: linear-gradient(
    180deg,
    rgba(244, 166, 97, 1) 0%,
    rgba(254, 205, 117, 1) 50%,
    rgba(222, 222, 165, 1) 100%
  );
}

#masjidLayoutA {
  /* ASR */
  background: linear-gradient(
    180deg,
    rgba(222, 222, 165, 1) 0%,
    rgba(254, 205, 117, 1) 50%,
    rgba(244, 166, 97, 1) 100%
  );
}

#masjidLayoutM {
  /* MAGHRIB */
  background: linear-gradient(
    180deg,
    rgba(155, 152, 212, 1) 0%,
    rgba(185, 170, 204, 1) 50%,
    rgba(202, 178, 200, 1) 100%
  );
}

#masjidLayoutI {
  /* ICHA */
  background: linear-gradient(180deg, rgba(21, 41, 141, 1) 0%, rgba(43, 64, 157, 1) 50%, rgba(93, 115, 206, 1) 100%);
}

.masjidLayout {
  position: relative;
  border-radius: 0 0 1rem 1rem;
  display: flex;
}

.masjidLayout img {
  max-width: 100%;
  padding-top: 10rem;
  border-radius: 0 0 1rem 1rem;
}

#actualTime {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.5rem;
  color: white;
  text-align: center;
  text-transform: uppercase;
  padding: 1rem;
  width: 100%;
}

#salat-hours-page {
  margin: 1rem 3rem 6rem 3rem;
}

.hour-salat {
  padding: 1rem;
  border-radius: 1rem;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
}

.hour-salat span {
  width: 30%;
}

.hour-salat span.name-french {
  text-align: left;
}

.hour-salat span.time-salat {
  text-align: center;
}

.hour-salat span.name-arabic {
  text-align: right;
}

.hour-salat span.notif-salat {
  width: 30px;
  margin-left: 1rem;
}

.hour-salat span.notif-salat .icons {
  width: 100%;
  color: #f1f0f5;
  display: none;
}

.hour-salat span.notif-salat .icons.notifOn {
  color: var(--main-color);
}

.hour-salat span.notif-salat .icons.notifSelected {
  display: block;
}

.hour-salat.current {
  background: var(--main-color);
  color: #fff;
  font-weight: bold;
}

.hour-salat.current span.notif-salat .icons.notifOn {
  color: #fff;
}

.mosqueeName {
  gap: 30px;
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2em;
}

#allSets {
  width: 90%;
  margin: 2rem auto;
}

.button {
  padding: 10px 15px;
  cursor: pointer;
  text-align: center;
  position: relative;
  border: none;
  outline: none;
  background: none;
  appearance: none;
  -webkit-appearance: none;
  color: #fff;
  transition: all 500ms;
}

.button:before,
.button:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
}

.button:before {
  transform: translate(-50%, -50%);
  width: var(--before-w, 100%);
  height: var(--before-h, 100%);
  border-radius: var(--before-r, 0.5rem);
  background: var(--main-color);
  transform-origin: 50% 50%;
  transition: width 0.3s, height 0.3s, border-radius 0.2s, background 0.3s;
  transition-delay: 0.2s, 0.2s, 0.2s, 0.2s;
}

.button[disabled] {
  cursor: default;
}

.button[disabled]:before {
  background-color: #cccccc;
  color: #666666;
}

.button:after {
  width: 16px;
  height: 16px;
  margin: -8px 0 0 -8px;
  background: #fff;
  z-index: 2;
  border-radius: 50%;
  transform: scale(0);
}

.button span {
  z-index: 1;
  display: block;
  position: relative;
  opacity: var(--span-o, 1);
  transform: scale(var(--span-s, 1));
  transition: opacity 0.3s, transform 0.2s;
  transition-delay: 0.2s;
}

#loginForm .button svg {
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  stroke: #fff;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  transform: translate(-50%, -50%);
  stroke-dasharray: 30px;
  stroke-dashoffset: var(--svg-o, 30px);
  transition: stroke-dashoffset 0.3s ease;
}

.button.processing {
  --before-w: 41px;
  --before-h: 41px;
  --before-r: 22px;
  --span-o: 0;
  --span-s: 0.6;
  --after-s: 1;
  cursor: not-allowed;
  pointer-events: none;
}

.button.processing .logStated {
  --svg-o: 60px;
}

.button.processing:before {
  -webkit-animation: pulse 3s linear forwards;
  animation: pulse 3s linear forwards;
}

.button.processing.logFail:before {
  background: var(--red-color);
  transition-delay: 0.2s, 0.2s, 0.2s, 3s;
}

.button.processing:after {
  -webkit-animation: dot 3s linear forwards;
  animation: dot 3s linear forwards;
}

.button.processing .logStated {
  transition-delay: 3s;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  width: 40%;
  margin: 30px auto 0 auto;
  padding-bottom: 85px;
}

.errorField {
  font-size: 10px;
  text-align: left;
  color: red;
  margin-bottom: 5px;
  margin-left: 10px;
}

.fieldsForm {
  width: 100%;
}

.form .field {
  margin: 2rem;
}

.form .fieldValue {
  padding: 1rem;
  border: 1px solid var(--main-color);
  border-radius: 2rem;
  width: 100%;
}

.form .fieldName {
  text-align: initial;
  margin: 1rem;
  margin-bottom: 20px;
  display: block;
}

.stripe-element {
  width: 100%;
  margin-bottom: 20px;
}

.stripe-error {
  color: red;
}

.main-title {
  text-align: center;
  padding: 20px;
  background-color: #04bf94;
  color: white;
  font-size: 28px;
  font-weight: bold;
  margin: 0;
}

.successAction {
  color: white;
  padding: 10px 0 10px 0px;
  margin: 0 0 30px 0px;
  background-color: green;
  text-align: center;
  border-radius: 0 0 16px 16px;
  width: 100%;
}
.problemAction {
  color: white;
  padding: 10px 0 10px 0px;
  margin: 0 0 30px 0px;
  background-color: #ffa500;
  text-align: center;
  border-radius: 0 0 16px 16px;
}
.errorAction {
  color: white;
  padding: 10px 0 10px 0px;
  margin: 0 0 30px 0px;
  background-color: rgba(255, 0, 0, 0.8);
  text-align: center;
  border-radius: 0 0 16px 16px;
}

.rights {
  padding: 10px;
  margin-bottom: 70px;
}

.rights h2,
.rights h3 {
  margin-bottom: 10px;
  color: var(--main-color);
}

.rights p {
  margin-bottom: 20px;
}

.field {
  position: relative;
}

.passwordReveal {
  position: absolute;
  right: 10px;
  opacity: 0.2;
  cursor: pointer;
}

.passwordReveal svg {
  stroke: grey;
  width: 35px;
}

.passwordReveal:hover {
  opacity: 1;
}

.contenu {
  padding: 5px;
  margin: 10px 0;
  border-bottom: 1px solid var(--main-color);
  width: 80%;
  position: relative;
}

.contenu h2 {
  text-align: center;
  margin-bottom: 10px;
}

.contenu iframe {
  width: 45%;
  height: 390px;
  margin: 10px auto;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.contenus {
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.donation {
  display: flex;
  gap: 30px;
  position: absolute;
  top: 78%;
  left: 67px;
  transform: translate(-50%, -50%);
  color: white;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.feedback {
  display: flex;
  gap: 30px;
  position: absolute;
  top: 78%;
  right: 0%;
  transform: translateY(-50%);
  color: white;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
}

.donation a,
.feedback a {
  padding: 15px;
}

.changePass {
  background: var(--main-color);
  color: white;
  cursor: pointer;
  border-radius: 10px;
}

.deleteAccount {
  margin-top: 20px;
  background: red;
  color: white;
  cursor: pointer;
  border-radius: 10px;
}

.changePass a,
.deleteAccount a {
  padding: 10px;
  display: block;
}

.menu-config li {
  cursor: pointer;
}

.react-table {
  padding: 1rem 1rem 1rem 0;
  display: flex;
}
.react-table table {
  width: 100%;
}
.react-table tr:last-child td {
  border-bottom: 0;
}
.react-table th,
.react-table td {
  text-align: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
  color: var(--grey-color);
  padding: 0.5rem;
}
.react-table th:last-child span,
.react-table td:last-child span {
  margin-right: 10px;
}
.react-table th {
  background-color: #eff0ef;
  border: none;
}
.react-table th > span {
  color: var(--main-color);
  width: fit-content;
}
.react-table span {
  margin-right: 0px;
}
.input-react-table {
  font-family: var(--main-color);
  font-size: 16px;
  color: var(--main-color);
  border: none;
  background-color: inherit;
}

.menu-config ul {
  display: flex;
  justify-content: space-around;
  list-style-type: none;
  margin: 30px 0;
  flex-wrap: wrap;
}

.menu-config li {
  margin: 10px 0;
}

.selected-values svg,
.react-table svg,
.modal button svg {
  stroke: var(--main-color);
  width: 35%;
  opacity: 0.7;
  transition: all 500ms;
  cursor: pointer;
}

.selected-values svg {
  width: 25px;
  height: 25px;
}

button.modal-toggle {
  background-color: var(--main-color);
  border-radius: 3px;
  margin-bottom: 20px;
  margin-right: 40px;
  padding: 12px 20px;
  transition: all 0.5s;
}

.add,
button.modal-toggle {
  color: #fff;
  cursor: pointer;
}

.justify-self-end {
  justify-self: end;
}

.button-div {
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  position: relative;
}

.selected-values {
  align-items: center;
  display: flex;
  font-size: 13px;
  justify-content: end;
  margin-right: 20px;
  position: absolute;
  transition: all 0.5s;
}

.selected-values span:first-of-type {
  margin-left: 5px;
}

.modal button svg {
  height: 25px;
}

.selected-values svg:hover,
.react-table svg:hover {
  opacity: 1;
}

/***********************/
/* form */

.form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-content label {
  align-self: baseline;
  margin-bottom: 10px;
}

.form-content .form-elem {
  border-radius: 8.5px;
  margin-bottom: 25px;
  padding: 10px 20px;
  width: 100%;
}

.form-content .form-elem:focus-visible {
  outline: 1px solid black;
}

.form-content textarea.form-elem {
  max-height: 190px;
  overflow: auto;
}

.form-content .title-content {
  font-weight: normal;
  margin: 30px 0;
}

.form-content .value {
  align-self: baseline;
  margin-bottom: 15px;
}

.form-content .value p:nth-of-type(2) {
  padding-left: 30px;
  margin-top: 5px;
}

.form-content .value p:nth-of-type(2) {
  padding-left: 30px;
  margin-top: 5px;
}

.form-content .comment {
  max-height: 155px;
  overflow-y: auto;
}

.form-content .error-show {
  font-size: 0.6em;
  color: red;
  margin-right: auto;
  padding: 5px 0;
}

.indexation {
  padding-bottom: 85px;
}

.w-100 {
  width: 100%;
}

@media (max-width: 800px) {
  .form {
    width: 81%;
  }
  .contenu iframe {
    width: 100%;
    height: 290px;
  }
}

@media (max-width: 630px) {
  .mosqueeName {
    font-size: 1.5em;
  }
  #container2 .setting h2 {
    font-size: 0.9em;
  }
}

@media (max-width: 500px) {
  .styles_faq-row-wrapper__3vA1D
    .styles_row-body__1NvUo
    .styles_faq-row__2YF3c
    .styles_row-title__1YiiY
    .styles_icon-wrapper__2cftw {
    max-width: 100px !important;
  }
  .contenu iframe {
    height: 200px;
  }
  .feedback {
    right: -24px;
    transform: translate(-15%, -50%);
  }
}

@media (max-width: 435px) {
  .mosqueeName {
    font-size: 1em;
  }
}
